import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <>
      <Typography variant='h6'>
        <p>
          Incepted in the year <b>2011,</b> at <b>Mohali (Punjab, India),</b> we{' '}
          <b>"Pharma Plast",</b> are a reckoned <b>Manufacturer </b>and
          <b> Supplier</b> of a wide range of{' '}
          <b>
            Plastic Cream Jars, Shampoo Bottles, Tablet Containers, Enema
            Bottles,
          </b>
          <b> Pet Bottles,</b> etc.
        </p>
      </Typography>

      <Typography variant='subtitle1'>
        <p>
          We are a working as a leader in this field. The offered products are
          manufactured by our skilled specialists using the ultimate quality raw
          materials with the help of advanced machines in compliance with the
          set industry norms. These products are broadly acceptable by our
          patrons for features like durability, easy to carry and high strength.
          We offer these products in various specifications as per the
          stipulations given by patrons.
        </p>
      </Typography>

      <Typography variant='subtitle1' sx={{ mb: 3 }}>
        We have developed a highly progressive infrastructure facility that
        facilitates us to develop an excellent array of products. This facility
        sprawls over a wide area of land and includes diverse subunits like
        procurement, designing, quality control, warehousing & packaging, etc.
        In order to ensure smooth production process, these units are equipped
        with latest machinery and up-to-date technology. Our professionals work
        in close sync with each other to satisfy the variegated requirements of
        our clients in the most efficient manner. Being a quality oriented firm,
        we strive hard to present our patrons with excellent quality products as
        per their necessities and attain their greatest satisfaction.
      </Typography>
      <Typography variant='subtitle1'>
        We “Pharma Plast” have gained success in the market by manufacturing a
        remarkable amount of{' '}
        <strong>
          Lotion Bottles, Plastic Syrup Bottles, Plastic Cream Jars, Shampoo
          Bottles, Tablet Containers, etc.
        </strong>{' '}
        We are a well-known and reliable company that is incorporated in the
        year 2011 at Mohali (Punjab, India) and developed a well functional and
        spacious infrastructural unit where we manufacture these products in an
        efficient manner. We are a Partnership firm that is managed under the
        supervision of "<strong>Mr. Karan Bedi</strong>" (Partner) and have
        gained huge clientele in the market.
      </Typography>
      <Divider sx={{ m: '2rem 0' }} />
      <Grid container spacing={1} marginBottom={isMobile ? 15 : 5}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h6' component='div'>
                WHY US
              </Typography>
              <Typography variant='body1'>
                Our high-tech infrastructure facility has enabled us to
                accomplish bulk and specific requirements of our honored clients
                in the best possible manner. We have segregated our
                infrastructure facility into various departments such as
                designing, manufacturing, quality testing, warehousing,
                packaging, etc. Equipped with high-end machines and modern
                technology, these units are regularly upgraded for smooth
                production line.
              </Typography>

              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper'
                }}
                variant='body1'
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[500] }}>A</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Advanced infrastructural base' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar sx={{ bgcolor: green[500] }}>C</Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Client-centric approach' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar sx={{ bgcolor: green[500] }}>E</Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Ethical business dealings' />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar sx={{ bgcolor: green[500] }}>S</Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Excellent logistic facility' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[500] }}>H</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Highly experienced workforce' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar sx={{ bgcolor: green[500] }}>H</Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Huge variety of products' />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[500] }}>S</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Stringent quality checking' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar sx={{ bgcolor: green[500] }}>T</Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Timely delivery' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar sx={{ bgcolor: green[500] }}>W</Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Wide distribution network' />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h6' component='div'>
                OUR INFRASTRUCTURE
              </Typography>
            </CardContent>
            <CardMedia
              sx={{ height: 650 }}
              image='images/infrastructure.jpg'
              title='Pharma Plast'
              alt='Pharma plast infrastructure'
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
