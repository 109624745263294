import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firestore-config';
import moment from 'moment';

export const fetchProducts = createAsyncThunk('products', async () => {
  try {
    const productCol = collection(db, 'products');
    const productSnapshot = await getDocs(productCol);
    const productList = productSnapshot.docs.map((doc) => {
      const list = {
        ...doc.data(),
        productId: doc.id
      };
      return list;
    });
    return productList
      .filter((a) => a.productId)
      .sort((a, b) => moment(b.createdDateTime).diff(a.createdDateTime));
  } catch (error) {
    console.log('getAllProducts', error);
    return [];
  }
});

const initialState = {
  products: [],
  filter: [],
  status: 'idle',
  error: ''
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    createProduct: (state, action) => {
      return {
        ...state,
        status: 'succeeded',
        products: action.payload
      };
    },

    removeProduct: (state, action) => {
      return {
        ...state,
        products: [...state.products].filter(
          (p) => p.productId !== `${action.payload}`
        )
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = state.products.concat(action.payload);
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});
export const { createProduct, removeProduct } = productsSlice.actions;
export const selectAllProducts = (state) => state.products.products;
export const selectByProductId = (state) => state.products.filter;
export const getProductError = (state) => state.products.error;
export const getProductStatus = (state) => state.products.status;

export default productsSlice.reducer;
