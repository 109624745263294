import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAuthUser, getEmailByLogin } from '../app/auth';
import { signOut } from 'firebase/auth';
import { auth } from '../firestore-config';

const Header = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getAuthUser);

  const pages = [
    { pageName: 'Home', pageUrl: '/', id: 1 },
    { pageName: 'Our Products', pageUrl: '/products', id: 2 }
  ];

  // test

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onClickNavigate = (urlName) => {
    setAnchorElNav(null);
    navigate(urlName);
  };

  const login = (url) => {
    navigate(url);
  };
  const logOutClick = () => {
    signOut(auth);
    dispatch(getEmailByLogin(''));
  };

  return (
    <AppBar
      position='fixed'
      color='success'
      sx={{ borderBottom: '2px solid #ba000d' }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            sx={{
              mr: 1,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.25rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            PHARMA PLAST
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.id}
                  onClick={() => onClickNavigate(page.pageUrl)}
                >
                  <Button textalign='center'>{page.pageName}</Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant='h6'
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            PHARMA PLAST
          </Typography>
          <Box sx={{ ml: 1, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Tooltip key={page.id} title={page.pageName}>
                <Button
                  key={page.id}
                  onClick={() => onClickNavigate(page.pageUrl)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.pageName}
                </Button>
              </Tooltip>
            ))}
          </Box>

          {!currentUser ? (
            <Tooltip key={'login'} title='Login'>
              <Button onClick={() => login('login')} color='inherit'>
                Login
              </Button>
            </Tooltip>
          ) : (
            <>
              <Typography variant='subtitle1' sx={{ mr: 2 }}>
                Karan Bedi
              </Typography>
              <Button
                title='Log out'
                color='inherit'
                size='small'
                onClick={logOutClick}
                endIcon={<LogoutIcon />}
              >
                Log Out
              </Button>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
