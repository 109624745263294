import React, { useCallback, useEffect } from 'react';
import { Fab, useScrollTrigger, Fade, Box } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const trigger = useScrollTrigger({
    target: window || undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const { pathname } = useLocation();

  const scrollTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fade in={trigger}>
      <Box
        role='presentation'
        // Place the button in the bottom right corner.
        sx={{ position: 'fixed', bottom: 55, right: 10 }}
      >
        <Fab
          onClick={scrollTop}
          size='small'
          aria-label='Scroll back to top'
          variant='extended'
        >
          <NavigationIcon />
          Top
        </Fab>
      </Box>
    </Fade>
  );
};
