import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useTheme } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Link,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useLocation } from 'react-router-dom';

const Contact = () => (
  <>
    <Grid container alignItems={'center'}>
      <Grid item md={6} lg={3} sm={12} xs={12}>
        <List sx={{ p: 0 }}>
          <ListItem>
            <ListItemAvatar>
              <AddLocationIcon fontSize='large' color='error' />
            </ListItemAvatar>
            <ListItemText
              primary='Plot No #808 Sector 82, JLPL Industrial Area'
              secondary='Mohali - 160055, Punjab, India'
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item md={6} lg={3} sm={12} xs={12}>
        <ListItem>
          <ListItemAvatar>
            <PhoneIcon fontSize='large' color='primary' />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div style={{ display: 'grid' }}>
                <Link
                  title='Karan Bedi: 9780-493-574'
                  href='tel:+919780493574'
                  underline='hover'
                >
                  9780-493-574
                </Link>

                <Link
                  title='Manvinder Singh: 9316-101-776'
                  href='tel:+919316101776'
                  underline='hover'
                >
                  9316-101-776
                </Link>
              </div>
            }
          />
        </ListItem>
      </Grid>

      <Grid item md={6} lg={3} sm={12} xs={12}>
        <ListItem>
          <ListItemAvatar>
            <WhatsAppIcon fontSize='large' color='success' />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div style={{ display: 'grid' }}>
                <Link
                  title='Chat on WhatsApp'
                  aria-label='Chat on WhatsApp'
                  target='_blank'
                  href='https://wa.me/919780493574'
                  underline='hover'
                >
                  Karan Bedi
                </Link>
                <Link
                  title='Chat on WhatsApp'
                  aria-label='Chat on WhatsApp'
                  target='_blank'
                  href='https://wa.me/919316101776'
                  underline='hover'
                >
                  Manvinder Singh
                </Link>
              </div>
            }
          />
        </ListItem>
      </Grid>
      <Grid item md={6} lg={3} sm={12} xs={12}>
        <ListItem>
          <ListItemAvatar>
            <EmailIcon fontSize='large' color='warning' />
          </ListItemAvatar>
          <ListItemText
            primary={
              <a href='mailto:pharmaplast11@gmail.com'>
                pharmaplast11@gmail.com
              </a>
            }
            secondary={
              <a href='mailto:airbmair@gmail.com'>airbmair@gmail.com</a>
            }
          />
        </ListItem>
      </Grid>
    </Grid>
    <Typography
      sx={{ mb: 1 }}
      align='center'
      variant='body2'
      color='text.secondary'
    >
      &copy; <strong>Pharma Plast </strong> {year} - All Rights Reserved
    </Typography>
  </>
);

const date = new Date();
let year = date.getFullYear();
const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'));
  const [expanded, setExpanded] = React.useState(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    setExpanded(false);
  }, [pathname]);

  return (
    <div>
      <Paper
        square
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'whitesmoke',
          borderTop: '1px solid grey'
        }}
      >
        <Container
          maxWidth='xl'
          sx={{ display: isMobile ? 'block' : 'contents' }}
        >
          {isMobile ? (
            <Contact />
          ) : (
            <div>
              <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              >
                <AccordionSummary
                  sx={{ backgroundColor: '#ff7961' }}
                  expandIcon={<ExpandLessIcon />}
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                >
                  <Container>
                    <Typography variant='button'>Contact Us</Typography>
                  </Container>
                </AccordionSummary>
                <AccordionDetails>
                  <Contact />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </Container>
      </Paper>
    </div>
  );
};

export default Footer;
