import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../views/login/Login';
import Home from '../views/screen/Home';
import Products from '../views/screen/Products';
import { useSelector } from 'react-redux';
import { getAuthUser } from '../app/auth';

const AppRoutes = () => {
  const currentUser = useSelector(getAuthUser);
  return (
    <Routes>
      <Route path='/*' element={<Home />} />
      <Route path='/products' element={<Products />} />
      <Route
        path='/login'
        element={!currentUser ? <Login /> : <Navigate to='/' />}
      />
    </Routes>
  );
};

export default AppRoutes;
