import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firestore-config';
import { getEmailByLogin } from '../../app/auth';
import { useDispatch } from 'react-redux';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((res) => {
        const { user } = res;
        dispatch(getEmailByLogin(user.email));
        navigate('/');
      })
      .catch((err) => setError('Email or Password is not valid'));
  };

  return (
    <>
      <Typography variant='h4' gutterBottom>
        <Divider>Login</Divider>
      </Typography>
      {error && (
        <>
          <Alert sx={{ mb: 3 }} severity='error'>
            {error}
          </Alert>
        </>
      )}
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5}>
            <TextField
              fullWidth
              id='email'
              size='small'
              label='Email'
              {...register('email', {
                required: 'Email is required'
              })}
              required
              error={errors.email?.type === 'required' ? true : false}
              variant='filled'
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextField
              fullWidth
              id='password'
              size='small'
              label='Password'
              type='password'
              {...register('password', {
                required: 'Password is required'
              })}
              error={errors.password?.type === 'required' ? true : false}
              variant='filled'
              helperText={errors.password?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              variant='contained'
              color='success'
              type='submit'
              size='medium'
              endIcon={<LoginIcon />}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
