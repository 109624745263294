import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Container } from '@mui/material';
import { Provider } from 'react-redux';
import store from './app/store';
import { ScrollToTop } from './components/ScrollToTop';
import { authUser } from './app/auth';
import AppRoutes from './routes';
import { fetchProducts } from './features/productsSlice';

store.dispatch(authUser());
store.dispatch(fetchProducts());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Header />
        <Container sx={{ padding: '6rem 0 2rem 0' }} maxWidth='xl'>
          <AppRoutes />
          <ScrollToTop />
          <Footer />
        </Container>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
