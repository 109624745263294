import { configureStore } from '@reduxjs/toolkit';
import getEmail from './auth';
import productsSlice from '../features/productsSlice';

export default configureStore({
  reducer: {
    email: getEmail,
    products: productsSlice
  }
});
