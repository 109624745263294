import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firestore-config';
import {
  createProduct,
  getProductStatus,
  removeProduct,
  selectAllProducts
} from '../../features/productsSlice';
import { collection, doc, deleteDoc, setDoc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable
} from 'firebase/storage';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  LinearProgress,
  Snackbar,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Delete as DeleteIcon } from '@mui/icons-material';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { getAuthUser } from '../../app/auth';
import { customAlphabet } from 'nanoid';

const Products = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const nanoid = customAlphabet(characters, 20);
  const currentUser = useSelector(getAuthUser);
  const products = useSelector(selectAllProducts);
  const status = useSelector(getProductStatus);

  const dispatch = useDispatch();
  // const error = useSelector(getProductError);
  const [isLoading, setIsloading] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [severityColor, setSeverityColor] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [deletedProduct, setDeletedProduct] = useState('');
  const [addProductForm, setAddProductForm] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  const metadata = {
    contentType: 'image/jpeg'
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const deleteProductByID = async () => {
    setIsloading(true);
    setOpenModal(false);
    await deleteDoc(doc(db, 'products', deletedProduct.productId));
    const storage = getStorage();
    // Create a reference to the file to delete
    deletedProduct.imgName.map(async (img) => {
      const imageRef = ref(storage, `images/${img}`);
      deleteObject(imageRef)
        .then()
        .catch((err) => console.log(err));
    });
    setIsloading(false);

    dispatch(removeProduct(deletedProduct.productId));
    setTimeout(() => {
      setSuccess(true);
      setConfirmMessage('deleted');
      setSeverityColor('error');
    }, 2000);
  };

  const onCancelClick = () => {
    setUploadImage([]);
    reset();
    setImageError(false);
    setAddProductForm(false);
  };

  const onSubmit = (data) => {
    const imgArry = [];
    const imgArryName = [];
    // Add a new document with a generated id
    const ProductId = doc(collection(db, 'products'));
    const storage = getStorage();
    // upload and save to Db
    [...uploadImage].forEach((img) => {
      const imgName = nanoid();
      const imagesRef = ref(storage, `images/${imgName}`);
      const file = new File([img], imgName);
      // Upload file and metadata to the object 'images/unqiueName'
      const uploadTask = uploadBytesResumable(imagesRef, file, metadata);
      imgArryName.push(imgName);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setIsloading(true);
        },
        (error) => {
          console.log('Error while uploading images', error.code);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            imgArry.push(downloadURL);
            await setDoc(ProductId, {
              ...data,
              createdDateTime: moment().toISOString(),
              imgUrl: imgArry,
              imgName: imgArryName
            });
            setIsloading(false);
            dispatch(
              createProduct([
                {
                  ...data,
                  productId: ProductId.id,
                  createdDateTime: moment().toISOString(),
                  imgUrl: imgArry,
                  imgName: imgArryName
                },
                ...products
              ])
            );
          });
          setTimeout(() => {
            setSuccess(true);
            setConfirmMessage('added');
            setSeverityColor('success');
            onCancelClick();
          }, 3000);
        }
      );
    });
  };

  const Delete = () => {
    return (
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alertdelete'>
          {`Are you sure you want to delete product: ${deletedProduct.title}`}
        </DialogTitle>
        <DialogActions>
          <Button
            variant='contained'
            color='error'
            onClick={() => setOpenModal(false)}
          >
            No
          </Button>
          <Button
            variant='outlined'
            color='success'
            onClick={deleteProductByID}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const AddProducts = () =>
    currentUser && (
      <>
        <Box
          component='form'
          noValidate
          sx={{ mb: 2 }}
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!addProductForm && (
            <Button
              variant='contained'
              onClick={() => {
                setAddProductForm(true);
              }}
              color='success'
            >
              Add New Product
            </Button>
          )}
          {addProductForm && (
            <div>
              <TextField
                fullWidth
                {...register('title', {
                  required: 'Product title field is required'
                })}
                required
                multiline
                rows={2}
                error={errors.title?.type === 'required' ? true : false}
                label='Product Title'
                variant='filled'
                helperText={errors.title?.message}
              />
              <Box sx={{ mt: '1rem' }}>
                {uploadImage.length === 0 && (
                  <Button sx={{ mr: 1 }} variant='outlined' component='label'>
                    Upload images
                    <input
                      hidden
                      multiple
                      accept='image/*'
                      onChange={(e) => {
                        e.preventDefault();
                        setUploadImage(e.target.files);
                      }}
                      type='file'
                    />
                  </Button>
                )}
                {uploadImage.length > 0 && (
                  <Button type='submit' variant='contained' color='success'>
                    Add New Product
                  </Button>
                )}
                <Button
                  variant='outlined'
                  sx={{ ml: 1 }}
                  onClick={onCancelClick}
                  color='error'
                >
                  Cancel
                </Button>
              </Box>
              {imageError && (
                <Alert sx={{ mt: 2 }} severity='error'>
                  Upload Image is required.
                </Alert>
              )}

              {uploadImage.length > 0 &&
                [...uploadImage].map((file, index) => {
                  return (
                    <Chip
                      key={index}
                      sx={{ mt: 2, ml: 1 }}
                      label={file.name}
                      color='success'
                      variant='outlined'
                    />
                  );
                })}
            </div>
          )}
        </Box>
      </>
    );

  if (status === 'loading') {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Divider sx={{ mb: 3 }}>
            <Chip color='error' variant='outlined' label='Loading...' />
          </Divider>
          <LinearProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      {success && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={success}
          autoHideDuration={10000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity={severityColor}
            sx={{ width: '100%' }}
          >
            Product {confirmMessage} successfully.
          </Alert>
        </Snackbar>
      )}
      {isLoading ? (
        <LinearProgress color='success' sx={{ mb: 3 }} />
      ) : (
        <AddProducts />
      )}
      <Divider sx={{ mb: 3 }}>
        <Chip color='error' label='OUR PRODUCTS' />
      </Divider>

      <ImageList cols={isMobile ? 3 : 1} variant='quilted' rowHeight={650}>
        {products.map((item) => {
          return (
            item?.imgUrl?.length > 0 &&
            item?.imgUrl.map((img) => (
              <ImageListItem
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: 0.4
                }}
                key={img}
              >
                <img
                  style={
                    !imgLoading
                      ? ''
                      : {
                          backgroundRepeat: 'no-repeat',
                          backgroundImage: 'url("./logo.png")',
                          backgroundPosition: 'center',
                          backgroundColor: 'HighlightText'
                        }
                  }
                  src={`${img}?w=248&fit=crop&auto=format`}
                  srcSet={`${img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading='lazy'
                  onLoad={() => setImgLoading(imgLoading)}
                />

                <ImageListItemBar
                  title={`Product: ${item.title}`}
                  subtitle={
                    'Added on - ' +
                    moment(`${item.createdDateTime}`).format(
                      'MMMM Do YYYY, h:mm a'
                    )
                  }
                  actionIcon={
                    currentUser && (
                      <>
                        <Tooltip
                          aria-label={`info about ${item.title}`}
                          title={'Share on WhatsApp'}
                        >
                          <IconButton>
                            <WhatsAppIcon fontSize='large' color='success' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          aria-label={`info about ${item.title}`}
                          title={'Delete Product'}
                        >
                          <IconButton>
                            <DeleteIcon
                              fontSize='large'
                              color='warning'
                              onClick={() => {
                                setOpenModal(true);
                                setDeletedProduct(item);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  }
                  position='top'
                />
              </ImageListItem>
            ))
          );
        })}
      </ImageList>

      {openModal && <Delete />}
    </>
  );
};

export default Products;
