import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { auth } from '../firestore-config';
import { onAuthStateChanged } from 'firebase/auth';

export const authUser = createAsyncThunk('auth', () => {
  const myPromise = new Promise((result) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        result(user.email);
      } else {
        result('');
      }
    });
  });

  return myPromise;
});

const initialState = {
  email: '',
  status: 'idle',
  error: ''
};

export const getEmail = createSlice({
  name: 'email',
  initialState,
  reducers: {
    getEmailByLogin: (state, action) => {
      state.email = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(authUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.email = action.payload;
      })
      .addCase(authUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Action creators are generated for each case reducer function
export const { getEmailByLogin } = getEmail.actions;
export const getAuthUser = (state) => state.email.email;
export const getAuthUserError = (state) => state.email.error;
export const getAuthUserStatus = (state) => state.email.status;

export default getEmail.reducer;
